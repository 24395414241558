/* CourseChapters.module.css */

.chapterWrapper {
    padding: 20px;
  }
  
  .chapterBox {
    background-color: #f8f9fa;
    border: 1px solid #dee2e6;
    border-radius: 5px;
    padding: 15px;
    margin-bottom: 20px;
  }
  
  .chapterTitle {
    color: #343a40;
  }
  
  .lectureDescription {
    color: #6c757d;
  }
  
  .lectureList {
    list-style-type: none;
    padding: 0;
  }
  
  .lectureItem {
    margin-bottom: 5px;
  }
  